<template>
  <div>
    入户服务协议
  </div>
</template>

<script>
export default {
  name: 'HomeService'
}
</script>

<style scoped>

</style>
